import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from './Button'

const ColumnContent = props => {

  const title = props.columnTitle || props.title
  const link = props.columnLink || props.uri

  // Resource image check
  const resource = props.acfResourceBuilder?.resourceBuilder && props.acfResourceBuilder.resourceBuilder.filter(component => component.__typename === 'WpResource_Acfresourcebuilder_ResourceBuilder_FullWidthImage')
  const resourceImg = resource && getImage(resource[0].fullWidthImage.localFile)
  
  const img = (props.columnImage && getImage(props.columnImage.localFile)) || resourceImg

  return (
    <div className={`grid-item gcc`}>
      <div className={`image-wrapper lj-filter`}>
        {img && <GatsbyImage image={img} alt={``} />}
        {title && <div className={`title`}>{title}</div>}
      </div>
      <div className={`details`}>
        {props.columnContent && <div className={`content`}>{props.columnContent}</div>}
        {link && <Button link={link} />}
      </div>
    </div>
  );
}

export default ColumnContent