import React, {useState} from 'react'
import parse from 'html-react-parser'
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Button from './Button'
import Player from '@vimeo/player'
import Lightbox from 'fslightbox-react'

const Hero = props => {

  const data = props.data

  const images = data.heroImage && getImage(data.heroImage.localFile.full)
  // const images = data.heroImage && withArtDirection(getImage(data.heroImage.localFile.full), [
  //   {
  //     media: "(max-width: 451px)",
  //     image: getImage(data.heroImage.localFile.mobile)
  //   },
  // ])
  // console.log(images)
  // console.log(getImage(data.heroImage.localFile.mobile))
  // console.log(getImage(data.heroImage.localFile.full))

  const [toggler, setToggler] = useState(false)

  const video = () => {
    return (
      <div role="button" aria-label="Open Video" tabIndex={0} className={`cta video-cta flex`} onClick={() => setToggler(!toggler)} onKeyDown={() => setToggler(!toggler)}>
        <div className={`button`}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 22v-20l18 10-18 10z"/></svg></div><div className={``}>{data.link.title}</div>
      </div>
    )
  }
  const button = () => {
    return (
      <div className={`cta`}>
        <Button link={data.link} />
      </div>
    )
  }

  return (
    <section className={`section hero`}>
      <header className={`wrapper`}>
        <div className={`hero-content flex flex-column`}>
          {data.heroHeadline && <h1 className={`headline`}>{parse(data.heroHeadline)}</h1>}
          {data.heroSubHeadline && <div className={`subhead`}>{data.heroSubHeadline}</div>}
          {data.hasCta ? data.ctaType === 'video' ? video() : button() : null}
        </div>
      </header>
      <div className={`hero-image`}>
        {images && <GatsbyImage className={`art`} image={images} loading="eager" alt={data.heroImage.altText} />}
      </div>
      {data.heroVideo && 
        <Lightbox 
          toggler={toggler} 
          sources={
            [<iframe src={data.heroVideo} 
              width="1920" 
              height="1080" 
              frameBorder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowFullScreen 
              title="LeJeune Steel Sizzle"
            />]} 
          onOpen={() => {
            setTimeout(function(){
              const iframe = document.querySelector('.fslightbox-container iframe')
              const player = new Player(iframe)
              player.on('loaded', function() {
                player.play()
              })
            }, 100)        
          }} 
        />}
    </section>
  );
}

export default Hero