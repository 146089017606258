import React from 'react'
import parse from "html-react-parser"
import {useStandardStyles} from './Helpers'
import Button from "./Button"

const ContentComponent = props => {

  const data = props.data
  const getClassNames = useStandardStyles('cc')
  const cx = getClassNames(data)
  const cols = data.listColumns !== null || data.listColumns === true ? 'column-list' : undefined 

  return (
    <section className={cx}>
      <div className={`wrapper flex flex-column `}>
        {data.headline && <div className={`headline`}>{data.headline}</div>}
        {data.content && <div className={`${cols}`}>
          {data.content && parse(data.content)}
        </div>}
        {data.sectionLink && <div className={`section-link`}><Button link={data.sectionLink} /></div>}
      </div>
    </section>
  )
}

export default ContentComponent